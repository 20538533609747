import window11 from './images/po-11.svg';
import window21 from './images/po-21.svg';
import window31 from './images/po-31.svg';
import window41 from './images/po-41.svg';
import window51 from './images/po-51.svg';
import window61 from './images/po-61.svg';
import window12 from './images/po-12.svg';
import window22 from './images/po-22.svg';
import window32 from './images/po-32.svg';
import window42 from './images/po-42.svg';
import window52 from './images/po-52.svg';
import window62 from './images/po-62.svg';
import bg from './images/bg.svg';
import mainWindow from './images/main-window.svg';

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './PlastikovyeOkna.css';
import UpperMenu from '../../../components/Elements/UpperMenu.jsx';
import Component13 from '../../../components/Elements/mainPage/Component13.jsx';
import PlastikovyeOkna_mob from './PlastikovyeOkna_mob.jsx';

const PlastikovyeOkna = () => {
  const [img1, setImg1] = useState({ img: window11, isHover: false });
  const [img2, setImg2] = useState({ img: window21, isHover: false });
  const [img3, setImg3] = useState({ img: window31, isHover: false });
  const [img4, setImg4] = useState({ img: window41, isHover: false });
  const [img5, setImg5] = useState({ img: window51, isHover: false });
  const [img6, setImg6] = useState({ img: window61, isHover: false });

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />

      {isDesktop > 480 ? (
        <div className="plastikovye-okna">
          <div className="plastikovye-okna__title">
            <h2>
              Пластиковые окна — это современное и практичное решение для вашего дома или квартиры.
              Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления
              любых помещений. <br></br>
              <br></br>Вот некоторые из них:
            </h2>
          </div>
          <div className="plastikovye-okna__top-image">
            <img src={mainWindow} alt="main-window" />
          </div>
          <div className="plastikovye-okna__more-info">
            Наведите курсор на окна, чтобы узнать подробнее
          </div>
          <div className="plastikovye-okna__container1">
            <div className="plastikovye-okna__window1">
              <img
                src={img1.img}
                alt="window11"
                onMouseEnter={() => setImg1({ img: window12, isHover: true })}
                onMouseLeave={() => setImg1({ img: window11, isHover: false })}
              />
              <div
                className={
                  img1.isHover
                    ? 'plastikovye-okna__title-card1--visible'
                    : 'plastikovye-okna__title-card1'
                }
                onMouseEnter={() => setImg1({ img: window12, isHover: true })}
                onMouseLeave={() => setImg1({ img: window11, isHover: false })}>
                <p>
                  {' '}
                  Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не
                  пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на
                  отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря
                  специальным стеклопакетам с солнцезащитой.
                </p>
              </div>
            </div>
            <div className="plastikovye-okna__window2">
              <img
                src={img2.img}
                alt="window21"
                onMouseEnter={() => setImg2({ img: window22, isHover: true })}
                onMouseLeave={() => setImg2({ img: window21, isHover: false })}
              />
              <div
                className={
                  img2.isHover
                    ? 'plastikovye-okna__title-card2--visible'
                    : 'plastikovye-okna__title-card2'
                }
                onMouseEnter={() => setImg2({ img: window22, isHover: true })}
                onMouseLeave={() => setImg2({ img: window21, isHover: false })}>
                <p>
                  Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и
                  создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и
                  повреждениям, благодаря качественной фурнитуре и противовзломным системам.
                </p>
              </div>
            </div>
            <div className="plastikovye-okna__window3">
              <img
                src={img3.img}
                alt="window31"
                onMouseEnter={() => setImg3({ img: window32, isHover: true })}
                onMouseLeave={() => setImg3({ img: window31, isHover: false })}
              />
              <div
                className={
                  img3.isHover
                    ? 'plastikovye-okna__title-card3--visible'
                    : 'plastikovye-okna__title-card3'
                }
                onMouseEnter={() => setImg3({ img: window32, isHover: true })}
                onMouseLeave={() => setImg3({ img: window31, isHover: false })}>
                <p>
                  Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в
                  покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не
                  теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50
                  лет и более.
                </p>
              </div>
            </div>
          </div>

          <div className="plastikovye-okna__container2">
            <div className="plastikovye-okna__window4">
              <img
                src={img4.img}
                alt="window41"
                onMouseEnter={() => setImg4({ img: window42, isHover: true })}
                onMouseLeave={() => setImg4({ img: window41, isHover: false })}
              />
              <div
                className={
                  img4.isHover
                    ? 'plastikovye-okna__title-card4--visible'
                    : 'plastikovye-okna__title-card4'
                }
                onMouseEnter={() => setImg4({ img: window42, isHover: true })}
                onMouseLeave={() => setImg4({ img: window41, isHover: false })}>
                <p>
                  Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого
                  интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные,
                  панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их
                  любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
                </p>
              </div>
            </div>
            <div className="plastikovye-okna__window5">
              <img
                src={img5.img}
                alt="window51"
                onMouseEnter={() => setImg5({ img: window52, isHover: true })}
                onMouseLeave={() => setImg5({ img: window51, isHover: false })}
              />
              <div
                className={
                  img5.isHover
                    ? 'plastikovye-okna__title-card5--visible'
                    : 'plastikovye-okna__title-card5'
                }
                onMouseEnter={() => setImg5({ img: window52, isHover: true })}
                onMouseLeave={() => setImg5({ img: window51, isHover: false })}>
                <p>
                  Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий,
                  террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают
                  микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность,
                  благодаря системам вентиляции и конденсата. Они также предотвращают появление
                  плесени и грибка, которые могут нанести вред здоровью.
                </p>
              </div>
            </div>
            <div className="plastikovye-okna__window6">
              <img
                src={img6.img}
                alt="window61"
                onMouseEnter={() => setImg6({ img: window62, isHover: true })}
                onMouseLeave={() => setImg6({ img: window61, isHover: false })}
              />
              <div
                className={
                  img6.isHover
                    ? 'plastikovye-okna__title-card6--visible'
                    : 'plastikovye-okna__title-card6'
                }
                onMouseEnter={() => setImg6({ img: window62, isHover: true })}
                onMouseLeave={() => setImg6({ img: window61, isHover: false })}>
                <p>
                  Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и
                  не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы
                  углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть
                  утилизированы после окончания срока службы.{' '}
                </p>
              </div>
            </div>
          </div>
          <div className="plastikovye-okna__text">
            <p>
              Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры.
              Они сочетают в себе качество, функциональность, эстетику и экономию.{' '}
            </p>
          </div>
        </div>
      ) : (
        <PlastikovyeOkna_mob />
      )}

      <Component13 />
    </>
  );
};
export default PlastikovyeOkna;
